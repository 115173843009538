import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import Header from "./common/Header";
import Footer from "./common/Footer";
import dogOnTheBed from "./SVG-icons/dogOnTheBed.png";
import hotelIcon from "./SVG-icons/hotelIcon.svg";
import responsabilityIcon from "./SVG-icons/responsabilityIcon.svg";
import mixIcon from "./SVG-icons/mixIcon.svg";
import culture from "./SVG-icons/culture.png";
import valuesbackground from "./SVG-icons/valuesbackground.svg";
import valuesImage from "./SVG-icons/valuesImage.png";
import valuesText from "./SVG-icons/valuesText.svg";
import './CompanyInfos.css';


const CompanyInfos = () => {
    

    return (
        <>
            <Header pageName='QUIENES SOMOS' />
            <div className="emptyDiv">  
            </div>
            <div className="presentationSection">
                <div className="presentationText presentationText2">
                    <h4>QUIENES SOMOS</h4>
                    <p>SOMOS UNA COMUNIDAD DEDICADA A ENVOLVER Y SER PARTE EN TODAS LAS ETAPAS DE LA VIDA DE LAS MASCOTAS.</p>
                </div>
                <img id="dogOnTheBed" src={dogOnTheBed} alt="dog on a bed" />
            </div>

            <div className="informationSection">
                <div className="informationLines blue">
                    <img src={responsabilityIcon} alt="responsability icon" />
                    <p>La responsabilidad que tenemos contigo está presente en cada paso del desarrollo en la formula de los alimentos. Una marca Súper Premium, con un equipo dedicado a la investigación constante en las innovaciones y tendencias que son necesarias en la prolongación de la vida de nuestras mascotas, asi como su bellez, seguridad, diverción y salud.</p>
                </div>
                <div className="informationLines orange">
                    <img src={mixIcon} alt="mix icon" />
                    <p>Tenemos la mejor mezcla de México, con ingredientes sin alteraciones genéticas, garantizamos la máxima calidad sin dejar a un lado el sabor y la diversión.</p>
                </div>
                <div className="informationLines purple">
                    <img src={hotelIcon} alt="hotel icon" />
                    <p>Con un hotel especializado para que estés tranquilo, que todo el cariño recreativo lo tendrá en nuestras manos dia tras dia, desarrollando su máximo potencial e interacción con sus amigos caninos. Su segundo hogar.</p>
                </div>
            </div>

            <div className="cultureSection">
                <div className="cultureContain">
                    <h2 className="orange">CULTURA</h2>
                    <p>Sabemos lo mágico de la relación que tienes con tu canino, por lo tanto, queremos desarrollarlo de tal forma en que viva plenemente, amado y contigo. Hacerte feliz por verlo más feliz.</p>
                </div>
                <img src={culture} alt="dog jumping to eat food" />
            </div>

            {/* <div className="valuesSection">
                <img id="valuesImage" src={valuesImage} alt="dog" />
                <img id="valuesText" src={valuesText} alt="values" />
                <div className="valuesContain">
                    <div className="valuesDiv">
                        <h4>EXCELENCIA</h4>
                        <p>Si nos exigimos lo mejor podemos dar lo mejor.</p>
                    </div>
                    <div className="valuesDiv">
                        <h4>RESPONSABILIDAD</h4>
                        <p>Es nuestro deber con la sociedad, con nuestras mascotas y el medio ambiente.</p>
                    </div>
                    <div className="valuesDiv">
                        <h4>PASIÓN</h4>
                        <p>Somos humanos y sentimentos en cada paso de esta familia. Tenemos la energia y la fuerza que se necesita para darte lo mejor de nosotros.</p>
                    </div>
                    <div className="valuesDiv">
                        <h4>LEALTAD</h4>
                        <p>Fieles con nuestro equipo y nuestros clientes.</p>
                    </div>
                </div>
                <img id="valuesBackground" src={valuesbackground} alt="values background" />
            </div> */}

            <Footer copyrightsBackground="orangeBackground" />
            
        </>
    );
};

export default CompanyInfos;