import React from 'react';
import "./Footer.css";
import facebookLogo from "../SVG-icons/facebookLogo.png";
import twitterLogo from "../SVG-icons/twitterLogo.png";
import instagramLogo from "../SVG-icons/instagramLogo.png";
import ergo from "../SVG-icons/blueErgoLogo.svg";

const Footer = ({ copyrightsBackground }) => {

    return (
        <footer>
            <div className="footerSection">
                <div className="linkSection">
                    <div className="linkBlock">
                        <h5>MAPA DEL SITIO</h5>
                        <ul>
                            <li>NUTRICIÓN CANINA</li>
                            <li>INVESTIGACIÓN</li>
                            <li>QUIENES SOMOS</li>
                            <li>BLOG</li>
                        </ul>
                    </div>
                    <div className="linkBlock">
                        <h5>ERGO®</h5>
                        <ul>
                            <li>CONTACTO</li>
                            <li>AVISO DE PRIVACIDAD</li>
                        </ul>
                    </div>
                    <div className="linkBlock">
                        <h5>SOCIAL MEDIA</h5>
                        <img className="socialMediaLogos" src={facebookLogo} alt="facebook" />
                        <img className="socialMediaLogos" src={twitterLogo} alt="twitter" />
                        <img className="socialMediaLogos" src={instagramLogo} alt="instagram" />
                    </div>
                </div>
                <div className="ergoLogoBlock">
                    <img src={ergo} alt="ergo logo" />
                </div>
            </div>
            <div className={copyrightsBackground ? ("copyrightsSection" + " " + copyrightsBackground) : ("copyrightsSection greenBackground")}>
                <p>Copyrights 2020</p>
            </div>
        </footer>
    );
};

export default Footer;