import React, { useState } from 'react';
import './Product.css';

const Product = ({ product, basket, changeBasket }) => {

    const [quantity, setQuantity] = useState(0);

    const addToBasket = () => {
        const product_ID = product.product_ID;
        var newBasket = { ...basket };
        if (newBasket[product_ID]) {
            newBasket[product_ID] += quantity;
        } else {
            newBasket[product_ID] = quantity;
        }
        changeBasket(newBasket);
        setQuantity(0);
    };

    return (
        <div className="productCard">
            <img src={product.image} alt={product.name} />
            <h4>{product.name}</h4>
            <p>{product.description}</p>
            <label htmlFor={"productQuantityInput"+product.product_ID}>Comprar : </label>
            <div className="productQuantity">
                <input 
                    type="number"
                    id={"productQuantityInput"+product.product_ID} 
                    value={quantity}
                    onChange={e => setQuantity(Number(e.target.value))}
                />
                <button onClick={addToBasket}>OK</button>
            </div>
            
        </div>
    );
};

export default Product;