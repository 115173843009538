import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const { REACT_APP_API_URL } = process.env;

const ModifyProduct = (props) => {

    const productId = props.match.params.id;
    const history = useHistory();

    const [firstLoading, setFirstLoading] = useState(true);

    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [description, setDescription] = useState("");

    const [adult, setAdult] = useState(false);
    const [littleAdult, setLittleAdult] = useState(false);
    const [puppy, setPuppy] = useState(false);

    const [imgFile, setImgFile] = useState(undefined);
    const [imgURL, setImgURL] = useState(undefined);

    if (firstLoading) {
        axios
        .get(`${REACT_APP_API_URL}/api/product/` + productId)
        .then(res => {
            setName(res.data.name);
            setPrice(res.data.price);
            setDescription(res.data.description);
            setAdult(res.data.adult);
            setLittleAdult(res.data.littleAdult);
            setPuppy(res.data.puppy);
            if (res.data.image) {
                setImgURL(res.data.image);
            }
        })
        .catch(err => console.log(err));
        setFirstLoading(false);
    }

    const selectImg = (e) => {
        const newImgFile = e.target.files[0];
        setImgFile(newImgFile);
        if (newImgFile) {
            var reader  = new FileReader();
            reader.addEventListener("load", function () {
                setImgURL(this.result);
            }, false);
            reader.readAsDataURL(newImgFile);
        } else {
            setImgURL(undefined);
        }
    };

    const sendProduct = () => {
        var datas = new FormData();
        datas.append('name', name);
        datas.append('price', Number(price));
        datas.append('description', description);
        datas.append('adult', adult);
        datas.append('littleAdult', littleAdult);
        datas.append('puppy', puppy);
        if (imgFile) {
            datas.append('image', imgFile, imgFile.name);
        }

        axios
        .post(`${REACT_APP_API_URL}/api/product/modify/` + productId, datas, { headers: { 'content-type': 'multipart/form-data', 'Authorization' : 'Bearer ' + localStorage.token }} )
        .then(res => console.log(res))
        .catch(err => console.log(err));
    };


    return (
        <>
            <button onClick={evt => history.push('/admin')}>Página anterior</button>

            <h3>Modificar los produtos</h3>

            <form>
                <p>
                    <label htmlFor="name">Nombre :</label>
                    <input
                        id="name"
                        type="text"
                        value={name}
                        onChange={evt => setName(evt.currentTarget.value)}
                    />
                    <br/>

                    <label htmlFor="price">Precio :</label>
                    <input
                        id="price"
                        type="number"
                        value={price}
                        onChange={evt => setPrice(evt.currentTarget.value)}
                    />
                    <br/>

                    <label htmlFor="description">Descripción :</label>
                    <br/>
                    <textarea
                        id="description"
                        type="text"
                        value={description}
                        onChange={evt => setDescription(evt.currentTarget.value)}
                    ></textarea>
                    <br/>

                    Tipo : <br/>
                    <input 
                        type="checkbox"
                        name="adultDog"
                        id="adultDog"
                        checked={adult}
                        onChange={e => setAdult(!adult)}
                    /> <label htmlFor="adultDog">Adulto</label><br />
                    <input 
                        type="checkbox"
                        name="littleAdultDog"
                        id="littleAdultDog" 
                        checked={littleAdult}
                        onChange={e => setLittleAdult(!littleAdult)}
                    /> <label htmlFor="littleAdultDog">Adulto raza pequeña</label><br />
                    <input 
                        type="checkbox"
                        name="puppyDog"
                        id="puppyDog" 
                        checked={puppy}
                        onChange={e => setPuppy(!puppy)}
                    /> <label htmlFor="puppyDog">Cachorro</label><br />
                    <br/>

                    <input type="file" onChange={selectImg} /> {/* escoge un archivo */}
                    {imgURL && <img src={imgURL} alt='img'/>}

                    <br/>
                    <input type="button" value="Enviar" onClick={sendProduct} />
                </p>
            </form>
            
            
        </>
    );
};

export default ModifyProduct;