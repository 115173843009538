import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from "../common/Header";
import Footer from "../common/Footer";
import CustomModal from "../common/CustomModal";


const { REACT_APP_API_URL } = process.env;

const Register = () => {

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [modalMessage, setModalMessage] = useState("");

    const sendInfo = (e) => {
        /* e.preventDefault(); */
        const registerInfo = {
            firstname,
            lastname,
            email,
            password,
            address,
            postalCode: Number(postalCode),
            city,
            state,
        };
        axios
        .post(`${REACT_APP_API_URL}/api/user/register`, registerInfo)
        .then(res => {
            setModalMessage("¡ Registro exitoso !");
            setTimeout(() => setModalMessage(""), 4000);
        })
        .catch(err => {
            setModalMessage("¡ Registro fallido !");
            setTimeout(() => setModalMessage(""), 4000);
        });
    };


    return (
        <>
            <Header />
            <div className="emptyDiv">  
            </div>

            <CustomModal message={modalMessage} show={modalMessage ? true:false} onHide={() => setModalMessage("")} />

            <div className="contactFormSection">
                <div className="registerForm">

                    <h1>Crear cuenta</h1>

                    <input
                        className="textInput"
                        placeholder="Nombre:"
                        type="text"
                        value={firstname}
                        onChange={evt => setFirstname(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Apellido:"
                        type="text"
                        value={lastname}
                        onChange={evt => setLastname(evt.currentTarget.value)}
                    />
                   
                    <input
                        className="textInput"
                        placeholder="Correo electrónico:"
                        type="text"
                        value={email}
                        onChange={evt => setEmail(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Contraseña:"
                        type="password"
                        value={password}
                        onChange={evt => setPassword(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Dirección:"
                        type="text"
                        value={address}
                        onChange={evt => setAddress(evt.currentTarget.value)}
                    />
                    
                    <input
                        className="textInput"
                        placeholder="Código postal:"
                        type="number"
                        value={postalCode}
                        onChange={evt => setPostalCode(evt.currentTarget.value)}
                    />
                    
                    <input
                        className="textInput"
                        placeholder="Ciudad:"
                        type="text"
                        value={city}
                        onChange={evt => setCity(evt.currentTarget.value)}
                    />
                    
                    <input
                        className="textInput"
                        placeholder="Estado:"
                        type="text"
                        value={state}
                        onChange={evt => setState(evt.currentTarget.value)}
                    />

                    <div className="formButtons">
                        <button className="purpleButton" onClick={sendInfo}>Validar</button>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Register;