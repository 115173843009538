import React from "react";
import { Route } from "react-router-dom";

import AddProduct from "./components/admin/AddProduct"
import ModifyProduct from "./components/admin/ModifyProduct"
import ListOfProducts from "./components/admin/ListOfProducts"
import Home from "./components/Home"
import DogFood from "./components/DogFood"
import AdultFood from "./components/foodPages/AdultFood"
import LittleAdultFood from "./components/foodPages/LittleAdultFood"
import PuppyFood from "./components/foodPages/PuppyFood"
import BasketPage from "./components/BasketPage"
import ContactForm from "./components/ContactForm"
import Login from "./components/connexion/Login"
import MyProfile from "./components/connexion/MyProfile"
import MyOrders from "./components/connexion/MyOrders"
import Register from "./components/connexion/Register"
import CompanyInfos from "./components/CompanyInfos"
import PaymentAccepted from "./components/payment/PaymentAccepted"
import PaymentDenied from "./components/payment/PaymentDenied"

const Router = () => {

  return (
    <>
      <Route exact path="/" component={Home} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/my-profile" component={MyProfile} />
      <Route exact path="/my-orders" component={MyOrders} />
      <Route exact path="/dog-food" component={DogFood} />
      <Route exact path="/dog-food/adult" component={AdultFood} />
      <Route exact path="/dog-food/little-adult" component={LittleAdultFood} />
      <Route exact path="/dog-food/puppy" component={PuppyFood} />
      <Route exact path="/about-us" component={CompanyInfos} />
      <Route exact path="/contact-form" component={ContactForm} />
      <Route exact path="/basket" component={BasketPage} />
      <Route exact path="/payment-accepted" component={PaymentAccepted} />
      <Route exact path="/payment-denied" component={PaymentDenied} />
      <Route exact path="/admin/addproduct" component={AddProduct} />
      <Route exact path="/admin/modifyproduct/:id" component={ModifyProduct} />
      <Route exact path="/admin" component={ListOfProducts} />
    </>
  );
};

export default Router;
