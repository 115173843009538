import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import trash from "../SVG-icons/trash.svg";
import "./Basket.css";

const { REACT_APP_API_URL } = process.env;

const Basket = ({ basket, changeBasket }) => {

    const history = useHistory();

    const [products, setProducts] = useState([]);

    useEffect(() => {
        axios
        .get(`${REACT_APP_API_URL}/api/product/all`)
        .then(res => setProducts(res.data))
        .catch(err => console.log(err));
    }, []);


    const totalAmount = () => {
        var result = 0;
        for (var product_ID in basket) {
            for (var product of products) {
                if (product.product_ID == product_ID) {
                    result += (product.price*basket[product_ID]);
                }
            }
        }
        return result;
    }


    const basketToCustomBasket = () => {
        var customBasket = [];
        for (var product_ID in basket) {
            for (var product of products) {
                if (product.product_ID == product_ID) {
                    customBasket.push({ ...product, quantity: basket[product_ID] });
                    break;
                }
            }
        }
        return customBasket;     
    };
    
    const removeProduct = (product_ID) => {
        var newBasket = { ...basket };
        if (newBasket[product_ID]) {
            delete newBasket[product_ID];
            changeBasket(newBasket);
        }
    };

    return (
        <>
            <div className="basketSection">
                <h2>Mi carrito</h2>
                <table>
                    <tbody>
                        {basketToCustomBasket().map(product => (
                            <tr key={product.product_ID}>
                                <td className="productNameTD">{product.name}</td>
                                <td className="productQuantityTD">{"x" + product.quantity}</td>
                                <td className="productPriceTD">{"$" + product.price }</td>
                                <td><button onClick={e => removeProduct(product.product_ID)}>
                                    <img src={trash} alt="trash" />
                                </button></td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <th>Total</th>
                            <th></th>
                            <th>{"$" + totalAmount()}</th>
                            <th></th>
                        </tr>
                    </tfoot>
                </table>
                
                <button onClick={e => history.push('/contact-form')} className="customButton">Confirmar mi carrito</button>
                <button onClick={e => changeBasket({})} className="customButton">Vaciar mi carrito</button>
            </div>
        </>
    );
};

export default Basket;