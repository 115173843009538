import React from 'react';
import { Link } from 'react-router-dom';
import Header from "./common/Header";
import Footer from "./common/Footer";
import dogInGrass from "./SVG-icons/dogInGrass.png";
import adultKibbles from "./SVG-icons/adultKibbles.png";
import littleAdultKibbles from "./SVG-icons/littleAdultKibbles.png";
import puppyKibbles from "./SVG-icons/puppyKibbles.png";
import './DogFood.css';

const DogFood = () => {

    return (
        <>
            <Header pageName='NUTRICIÓN PERROS' />
            <div className="emptyDiv">  
            </div>
            <div className="presentationSection">
                <div className="presentationText presentationText2">
                    <h4>NUESTROS PRODUCTOS</h4>
                    <p>CONCIENTES DE LO IMPORTANTE QUE SON EN NUESTRAS VIDAS, MEJORAMOS LA SALUD Y BELLEZA DE TUS MASCOTAS A TRAVÉS DE LA ALIMENTACIÓN PARA BRINDARLES UNA CALIDAD DE VIDA.</p>
                </div>
                <img id="dogInGrass" src={dogInGrass} alt="dog in grass" />
            </div>

            <div className="foodTypeSection">
                <div className="foodTypes" id="adultKibbles">
                    <img src={adultKibbles} alt="adult kibbles" />
                    <p><Link to="/dog-food/adult">ADULTO</Link></p>
                </div>
                <div className="foodTypes" id="littleAdultKibbles">
                    <img src={littleAdultKibbles} alt="adult kibbles" />
                    <p><Link to="/dog-food/little-adult">ADULTO RAZA PEQUEÑA</Link></p>
                </div>
                <div className="foodTypes" id="puppyKibbles">
                    <img src={puppyKibbles} alt="adult kibbles" />
                    <p><Link to="/dog-food/puppy">CACHORRO</Link></p>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default DogFood;