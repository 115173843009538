import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import Header from "./common/Header";
import Footer from "./common/Footer";
import girlWithDog from "./SVG-icons/girlWithDog.png";
import heartBubble from "./SVG-icons/heartBubble.svg";
import starsBubble from "./SVG-icons/starsBubble.svg";
import dogBubble from "./SVG-icons/dogBubble.svg";
import leafBubble from "./SVG-icons/leafBubble.svg";
import blueBackground from "./SVG-icons/blueBackground.png";
import kibbles from "./SVG-icons/kibbles.svg";
import dogWithKibbles from "./SVG-icons/dogWithKibbles.png";
import formula from "./SVG-icons/formula.svg";
import girlWalkingHerDog from "./SVG-icons/girlWalkingHerDog.svg";
import localLogo from "./SVG-icons/localLogo.svg";
import './Home.css';
import NewsSection from "./common/NewsSection";

import DogRegister from "./connexion/DogRegister";

const Home = () => {
   

    return (
        <>
            <Header pageName='INICIO' />
            <div className="emptyDiv">  
            </div>
            {/* <DogRegister/> */}
            <NewsSection/>

            <div className="bubbleSection">
                <div className="bubble">
                    <img src={leafBubble} alt="leaf" />
                    <p id="bubbleText1">
                        <strong>BONDAD NATURAL</strong><br/>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    </p>
                </div>
                <div className="bubble">
                    <img src={starsBubble} alt="stars" />
                    <p id="bubbleText2">
                        <strong>INDULGENCIA PREMIUM</strong><br/>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    </p>
                </div>
                <div className="bubble">
                    <img src={heartBubble} alt="heart" />
                    <p id="bubbleText3">
                        <strong>ESTILO DE VIDA SALUDABLE</strong><br/>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    </p>
                </div>
                <div className="bubble">
                    <img src={dogBubble} alt="dog" />
                    <p id="bubbleText4">
                        <strong>HALLAZGOS EMOCIONALES</strong><br/>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    </p>
                </div>
            </div>

            <div className="scientificSection">
                <h2>SOMOS CIENTIFICOS</h2>
                <div className="scientificSection2">
                    <img id="blueBackground" src={blueBackground} alt="blue background" />
                    <div className="scientificSectionContain">
                        <div className="scientificSectionText">
                            <ul>
                                <li><strong>24%</strong> de proteína cruda en garantía.</li>
                                <li>Mix multivitamínico necesario para el funcionamiento celular, su desarrollo y crecimiento optimo.</li>
                                <li>Formula desarrollada en laboratorio especializado en nutrición canina.</li>
                                <li>Ingredientes naturales, extractos de vitaminas y minerales.</li>
                                <li>Alta desestabilidad, el <strong>90%</strong> de los ingredientes son nutrientes directos para su crecimiento.</li>
                            </ul>
                        </div>
                        <div className="scientificSectionKibbles">
                            <img src={kibbles} alt="kibbles" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="formula">
                <div className="formulaImage">
                    <img id="dogWithKibbles" src={dogWithKibbles} alt="dog with kibbles" />
                </div>
                <div className="formulaText">
                    <div className="formulaTitle">
                        <h2>FORMULA</h2>
                        <img src={formula} alt="formula" />
                    </div>
                    <p>
                        <strong>24%</strong> de proteína.
                    </p>
                    <ul>
                        <li>Antioxidantes naturales, que retrasan el envejecimiento Cada momento contigo es vital para tu mascota.</li>
                        <li>Extrusión de carne fresca como ingrediente principal.</li>
                        <li>Empaque totalmente compostable, porque el cuidado del medio ambiente es responsabilidad de todos.</li>
                    </ul>
                    
                </div>
            </div>

            <div className="localSection">
                <div className="localSectionContain">
                    <p>Ingredientes, proveedores y maquinaria mexicana, creemos y apoyamos lo nacional, juntos podemos lograr grandes cosas y grandes marcas.</p>
                    <img src={localLogo} alt="100% local" />
                </div>
                <img id="girlWalkingHerDog" src={girlWalkingHerDog} alt="girl walking her dog" />
            </div>

            <Footer copyrightsBackground="orangeBackground" />
            
        </>
    );
};

export default Home;