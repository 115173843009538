import React from 'react';
import './index.css';
import Router from "./Router";
import 'bootstrap/dist/css/bootstrap.min.css';



function App() {
  return (
    <div>
      <Router/>
    </div>
  );
}

export default App;
