import React from 'react';
import Header from "../common/Header";
import Footer from "../common/Footer";
import './payment.css';

const PaymentAccepted = () => {

    return (
        <>
            <Header pageName='NUTRICIÓN PERROS' />
            <div className="emptyDiv">  
            </div>
            
            <div className="paymentSection">
                <div className="paymentSection2 blueBackground">
                    <h2>Paiement accepté !</h2>
                    <p>Merci pour votre acaht ! Le paiement a été validé. Vous allez recevoir dans quelques instants un mail avec la facture correspondant à votre achat.</p>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PaymentAccepted;