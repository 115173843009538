import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import Header from "./common/Header";
import Footer from "./common/Footer";
import './ContactForm.css';

const { REACT_APP_API_URL, REACT_APP_STRIPE_PUBLIC_KEY } = process.env;

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);


const ContactForm = () => {

    useEffect(() => {
        axios
        .post(`${REACT_APP_API_URL}/api/user/infos`, {} , { headers: { 'Authorization' : 'Bearer ' + localStorage.token }})
        .then(res => {
            console.log(res.data);
            const user = res.data.user;
            setFirstname(user.firstname);
            setLastname(user.lastname);
            setAddress(user.address);
            setPostalCode(user.postalCode);
            setCity(user.city);
            setState(user.state);
            setEmail(user.email);
        })
        .catch(err => console.log(err.response.data.error));
    }, []);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [email, setEmail] = useState("");

    const stripeRedirection = async () => {
        const contact = {
            firstname,
            lastname,
            address,
            postalCode,
            city,
            state,
            email,
        };

        // Get Stripe.js instance
        const stripe = await stripePromise;
    
        // Call your backend to create the Checkout Session
        const response = await axios.post(`${REACT_APP_API_URL}/api/product/create-checkout-session-from-basket`, { basket: JSON.parse(localStorage.getItem('basket')), contact }, { headers: { 'Authorization' : 'Bearer ' + localStorage.token }});
            
        const session = response.data;
    
        // When the customer clicks on the button, redirect them to Checkout.
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
    
        if (result.error) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        }
    };

    const paypalRedirection = async () => {
        const contact = {
            firstname,
            lastname,
            address,
            postalCode,
            city,
            email,
        };
        
        axios
        .post(`${REACT_APP_API_URL}/api/product/paypal-payment`, { basket: JSON.parse(localStorage.getItem('basket')), contact }, { headers: { 'Authorization' : 'Bearer ' + localStorage.token }})
        .then(res => window.location.assign(res.data.redirection))
        .catch(err => console.log(err));

    };

    return (
        <>
            <Header />
            <div className="emptyDiv">  
            </div>
            
            <div className="contactFormSection">
                <div className="registerForm">
                    
                    <h1>Dirección de entrega</h1>

                    <input
                        className="textInput"
                        placeholder="Nombre:"
                        type="text"
                        value={firstname}
                        onChange={evt => setFirstname(evt.currentTarget.value)}
                    />
                
                    <input
                        className="textInput"
                        placeholder="Apellido:"
                        type="text"
                        value={lastname}
                        onChange={evt => setLastname(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Dirección:"
                        type="text"
                        value={address}
                        onChange={evt => setAddress(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Código postal:"
                        type="number"
                        value={postalCode}
                        onChange={evt => setPostalCode(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Ciudad:"
                        type="text"
                        value={city}
                        onChange={evt => setCity(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Ciudad:"
                        type="text"
                        value={state}
                        onChange={evt => setState(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Correo electrónico:"
                        id="email"
                        type="text"
                        value={email}
                        onChange={evt => setEmail(evt.currentTarget.value)}
                    />

                    <div className="formButtons">
                        <button className="purpleButton" onClick={stripeRedirection}>Pagar con tarjeta de crédito</button>
                        <button className="purpleButton" onClick={paypalRedirection}>Otro método de pago</button>
                    </div>
                </div>
            </div>
            <Footer copyrightsBackground="orangeBackground" />
        
        </>
    );
};

export default ContactForm;