import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from "../common/Header";
import Footer from "../common/Footer";
import "./MyProfile.css";

const { REACT_APP_API_URL } = process.env;

const MyProfile = () => {

    const history = useHistory();

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [address, setAddress] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");

    const [name, setName] = useState("");
    const [gender, setGender] = useState("male");
    const [age, setAge] = useState("");
    const [weight, setWeight] = useState("");
    const [breed, setBreed] = useState("");


    useEffect(() => {
        axios
        .post(`${REACT_APP_API_URL}/api/user/infos`, {} , { headers: { 'Authorization' : 'Bearer ' + localStorage.token }})
        .then(res => {
            const user = res.data.user;
            setFirstname(user.firstname);
            setLastname(user.lastname);
            setAddress(user.address);
            setPostalCode(user.postalCode);
            setCity(user.city);
            setState(user.state);
            setEmail(user.email);
        })
        .catch(() => history.push('/login'));

        axios
        .post(`${REACT_APP_API_URL}/api/user/pet-infos`, {} , { headers: { 'Authorization' : 'Bearer ' + localStorage.token }})
        .then(res => {
            console.log(res.data);
            setName(res.data.name);
            setGender(res.data.gender);
            setAge(res.data.age);
            setWeight(res.data.weight);
            setBreed(res.data.breed);
        })
        .catch(err => console.log(err));
    }, []);

    const sendInfo = (e) => {
        const registerInfo = {
            firstname,
            lastname,
            email,
            address,
            postalCode: Number(postalCode),
            city,
            state,
        };
        axios
        .post(`${REACT_APP_API_URL}/api/user/register`, registerInfo)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    };


    const modifyPetInfos = () => {
        const petInfos = {
            name,
            gender,
            age: Number(age),
            weight: Number(weight),
            breed,
        };
        axios
        .post(`${REACT_APP_API_URL}/api/user/pet`, petInfos, { headers: { 'Authorization' : 'Bearer ' + localStorage.token }})
        .then(res => console.log(res.data))
        .catch(err => console.log(err));
    }


    return (
        <>
            <Header />
            <div className="emptyDiv">  
            </div>

            <div className="contactFormSection myProfileSection">
                <div className="myInfos">
                    <div className="registerForm">

                        <h1>Mes infos</h1>

                        <input
                            className="textInput"
                            placeholder="Prénom:"
                            type="text"
                            value={firstname}
                            onChange={evt => setFirstname(evt.currentTarget.value)}
                        />

                        <input
                            className="textInput"
                            placeholder="Nom:"
                            type="text"
                            value={lastname}
                            onChange={evt => setLastname(evt.currentTarget.value)}
                        />

                        <input
                            className="textInput"
                            placeholder="E-Mail:"
                            type="text"
                            value={email}
                            onChange={evt => setEmail(evt.currentTarget.value)}
                        />

                        <input
                            className="textInput"
                            placeholder="Adresse:"
                            type="text"
                            value={address}
                            onChange={evt => setAddress(evt.currentTarget.value)}
                        />

                        <input
                            className="textInput"
                            placeholder="Code postale:"
                            type="number"
                            value={postalCode}
                            onChange={evt => setPostalCode(evt.currentTarget.value)}
                        />

                        <input
                            className="textInput"
                            placeholder="Ville:"
                            type="text"
                            value={city}
                            onChange={evt => setCity(evt.currentTarget.value)}
                        />

                        <input
                            className="textInput"
                            placeholder="Etat:"
                            type="text"
                            value={state}
                            onChange={evt => setState(evt.currentTarget.value)}
                        />

                        <div className="formButtons">
                            <button className="purpleButton" onClick={sendInfo}>Modifier</button>
                        </div>
                    </div>
                </div>

                <div className="myDogsInfos">
                    <div className="registerForm">

                        <h1>Mon chien</h1>

                        <input
                            type="text"
                            className="textInput"
                            placeholder="Nombre:"
                            value={name}
                            onChange={evt => setName(evt.currentTarget.value)}
                        />

                        <select
                            className="textInput"
                            value={gender} 
                            onChange={evt => setGender(evt.currentTarget.value)}
                        >
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>

                        <input
                            type="number"
                            className="textInput"
                            placeholder="Edad:"
                            value={age}
                            onChange={evt => setAge(evt.currentTarget.value)}
                        />

                        <input
                            type="number"
                            className="textInput"
                            placeholder="Peso:"
                            value={weight}
                            onChange={evt => setWeight(evt.currentTarget.value)}
                        />

                        <input
                            type="text"
                            className="textInput"
                            placeholder="Raza:"
                            value={breed}
                            onChange={evt => setBreed(evt.currentTarget.value)}
                        />

                        <div className="formButtons">
                            <button className="purpleButton" onClick={modifyPetInfos}>Modifier</button>
                        </div>
                    </div>
                </div>
                
            </div>

            <Footer />
        </>
    );
};

export default MyProfile;