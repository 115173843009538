import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from "../common/Header";
import Footer from "../common/Footer";
import ProductCards from "./ProductCards";
import './foodpages.css';

const { REACT_APP_API_URL } = process.env;

const LittleAdultFood = () => {

    const [products, setProducts] = useState([]);

    useEffect(() => {
        axios
        .get(`${REACT_APP_API_URL}/api/product/little-adults`)
        .then(res => setProducts(res.data.map(product => ({ ...product, quantity: 0 }))))
        .catch(err => console.log(err));
    }, []);


    return (
        <>
            <Header pageName='NUTRICIÓN PERROS' />
            <div className="emptyDiv">  
            </div>
            <section>
                <h1>ADULTO</h1>
                <ProductCards products={products} />
            </section>
            <Footer copyrightsBackground="orangeBackground" />
        </>
    );
};

export default LittleAdultFood;