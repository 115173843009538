import React, { useState } from 'react';
import Product from "../common/Product";
import Basket from "../common/Basket";
import './foodpages.css';

const ProductCards = ({ products }) => {

    const [basket, setBasket] = useState(JSON.parse(localStorage.getItem('basket')) || {});

    const changeBasket = (newBasket) => {
        setBasket(newBasket);
        localStorage.setItem('basket', JSON.stringify(newBasket));
    };


    return (
        <>
            <div className="mainSection">
                <div className="productSection">
                    {products.map(product => (
                        <Product key={product.product_ID} product={product} basket={basket} changeBasket={changeBasket} />
                    ))}
                </div>
            </div>
            <Basket basket={basket} changeBasket={changeBasket} />
        </>
    );
};

export default ProductCards;