import React, { useState } from 'react';
import Header from "./common/Header";
import Footer from "./common/Footer";
import Basket from "./common/Basket";
import "./BasketPage.css";


const BasketPage = () => {

    const [basket, setBasket] = useState(JSON.parse(localStorage.getItem('basket')) || {});

    const changeBasket = (newBasket) => {
        setBasket(newBasket);
        localStorage.setItem('basket', JSON.stringify(newBasket));
    };

    return (
        <>
            <Header />
            <div className="emptyDiv">  
            </div>

            <div className="basketPage">
                <Basket basket={basket} changeBasket={changeBasket} />
            </div>

            <Footer copyrightsBackground="orangeBackground" />
        </>
    );
};

export default BasketPage;