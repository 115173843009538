import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from "../common/Header";
import Footer from "../common/Footer";
import "./MyOrders.css";

const { REACT_APP_API_URL } = process.env;

const MyOrders = () => {

    const history = useHistory();

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        axios
        .post(`${REACT_APP_API_URL}/api/user/my-orders`, {}, { headers: { 'Authorization' : 'Bearer ' + localStorage.token }})
        .then(res => {
            var newOrders = res.data;
            for (var orderId in newOrders) {
                var order = newOrders[orderId];
                var amount = 0;
                for (var product of order.products) {
                    amount += (product.price*product.quantity);
                }
                const newDate = new Date(order.date);
                const customDate = newDate.getDate() + "/" + (newDate.getMonth()+1) + "/" +  newDate.getFullYear();
                newOrders[orderId] = { ...order, date: customDate , amount };
            }
            console.log(newOrders);
            setOrders(newOrders);
        })
        .catch(err => history.push('/login'));
    }, []);


    return (
        <>
            <Header />
            <div className="emptyDiv">  
            </div>

            <div className="ordersBody">
                <h1>Mes commandes</h1>

                <div className="ordersSection">
                    
                    {orders.map(order => (
                        <div key={order.order_ID} className="orderCard">
                            <h4>Commande du {order.date}</h4>
                            <table>
                                <tbody>
                                    {order.products.map(product => (
                                        <tr key={product.product_ID}>
                                            <td className="productNameTD">{product.name}</td>
                                            <td className="productQuantityTD">{"x" + product.quantity}</td>
                                            <td className="productPriceTD">{product.price + "$"}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th></th>
                                        <th>{order.amount + "$"}</th>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    ))}

                    

                </div>
            </div>

            
            

            <Footer />
        </>
    );
};

export default MyOrders;