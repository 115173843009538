import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import "./Header.css";
import UserMenu from "./UserMenu";
import HeaderNav from "./HeaderNav";
import backgroundMenu from "../SVG-icons/backgroundMenu.png";
import basket from "../SVG-icons/basket.svg";
import ergo from "../SVG-icons/whiteErgoLogo.svg";
import menuIcon from "../SVG-icons/menuIcon.svg";

const { REACT_APP_API_URL } = process.env;

const Header = ({ pageName }) => {

    const history = useHistory();
    const [menu, setMenu] = useState(false);

    const [userName, setUserName] = useState("");
    const [isAdmin, setIsAdmin] = useState(false);

    const [largeur, setLargeur] = useState(window.innerWidth);

    useEffect(() => {

        const changeWidth = () => {
            setLargeur(window.innerWidth);
        }

        window.addEventListener('resize', changeWidth);

        axios
        .post(`${REACT_APP_API_URL}/api/user/infos`, {} , { headers: { 'Authorization' : 'Bearer ' + localStorage.token }})
        .then(res => {
            setUserName(res.data.user.firstname);
            setIsAdmin(res.data.user.admin);
        })
        .catch(err => console.log(err.response.data.error));

        return () => {
            window.removeEventListener('resize', changeWidth);
        }
    }, []);

    

    return (
        <header>
            <div className="menuContain">
                <button onClick={e => setMenu(!menu)} className="menuButton">
                    <img src={menuIcon} alt="menu icon" />
                    <p>MENU</p>
                </button>
                <Link to='/' className="homeLink">
                    <img className="ergoLogo" src={ergo} alt="ergo logo" />
                </Link>

                {(largeur > 700) && <HeaderNav pageName={pageName} userName={userName} />}
                
                <button onClick={e => history.push('/basket')} className="basketButton">
                    <img id="basket" src={basket} alt="basket" />
                </button>
            </div>
            <img id="backgroundMenu" src={backgroundMenu} alt="background menu" />
            {menu && <UserMenu firstname={userName} setFirstname={setUserName} admin={isAdmin} pageName={pageName} largeur={largeur} />}
        </header>
    );
};

export default Header;