import React from 'react';
import { Link } from 'react-router-dom';
import paw from "../SVG-icons/paw.svg";

const HeaderNav = (props) => {

    const pages = [
        { name: 'INICIO', link: '/'},
        { name: 'NUTRICIÓN PERROS', link: '/dog-food'},
        { name: 'QUIENES SOMOS', link: '/about-us'},
        { name: 'HOTEL', link: '/'},
        { name: 'SEGURO MÉDICO', link: '/'},
        { name: 'LOGIN', link: '/login'}
    ];

    const pageList = () => {
        var result = [];
        for (var page of pages) {
            if (page.name === props.pageName) {
                result.push({ pageName: page.name, className: 'selected', path: page.link });
            } else {
                result.push({ pageName: page.name, path: page.link });
            }
        }
        return result;
    }

    return (
        <div className="buttonSection">
            {pageList().map(({ pageName, className, path }, index) => (
                pageName==='LOGIN' ? (
                    <Link to={path} className={className} key={index} >
                        <div className="loginButtonSection">
                            <p className="loginButtonMainText">LOGIN</p>
                            <img src={paw} alt="paw" />
                            <p className="loginButtonSecondText">({props.userName || "USUARIO CANINO"})</p>
                        </div>
                    </Link>
                ) : (
                    <Link to={path} className={className} key={index}>{pageName}</Link>
                )
            ))}
            
        </div>
    );
};

export default HeaderNav;