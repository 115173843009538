import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import FileDownload from '../common/FileDownload';
import Header from "../common/Header";
import Footer from "../common/Footer";
import "./admin.css";

const { REACT_APP_API_URL } = process.env;

const ListOfProducts = () => {

    const history = useHistory();

    const [products, setProducts] = useState([]);
    const [date1, setDate1] = useState("");
    const [date2, setDate2] = useState("");

    const [onDownload1, setOnDownload1] = useState(false);
    const [onDownload2, setOnDownload2] = useState(false);

    const begin = new Date(date1);
    var end = new Date(date2);
    end.setDate(end.getDate()+1); 
    
    useEffect(() => {
        axios
        .get(`${REACT_APP_API_URL}/api/product/all`)
        .then(res => setProducts(res.data))
        .catch(err => console.log(err));
    }, [])

    
    const deleteProduct = (id) => {
        axios
        .delete(`${REACT_APP_API_URL}/api/product/` + id)
        .then(res => console.log(res))
        .catch(err => console.log(err));
    };


    return (
        <>
            {
                onDownload1 && 
                <FileDownload 
                    actionPath={`${REACT_APP_API_URL}/api/csv/orders`}
                    method='POST'
                    onDownloadComplete={() => setOnDownload1(false)}
                    queryParams={{ begin, end }}
                />
            }
            {
                onDownload2 && 
                <FileDownload 
                    actionPath={`${REACT_APP_API_URL}/api/csv/productOrders`}
                    method='POST'
                    onDownloadComplete={() => setOnDownload2(false)}
                    queryParams={{ begin, end }}
                />
            }

            <Header pageName='INICIO' />
            <div className="emptyDiv">  
            </div>

            <div className="adminPage">
                <h3>Lista de productos</h3>
                <ul>
                    {products.map(product => (
                        <div key={product.product_ID}>
                            <li>{product.name}</li>
                            <button onClick={evt => history.push('/admin/modifyproduct/' + product.product_ID)} >Modificar el producto</button>
                            {/* <button onClick={evt => deleteProduct(product.product_ID)} >Eliminar el producto</button> */}
                        </div>
                    ))}
                </ul>
                <button onClick={evt => history.push('/admin/addproduct')}>Añadir un producto</button><br/>
                <p>
                    Generar CSV del
                    <input
                        type="date"
                        value={date1}
                        onChange={e => setDate1(e.target.value)}
                    />
                    al
                    <input
                        type="date"
                        value={date2}
                        onChange={e => setDate2(e.target.value)}
                    />
                </p>
                <button onClick={() => setOnDownload1(true)}>Descargar pedidos</button>
                <button onClick={() => setOnDownload2(true)}>Descargar solicitud por producto</button>
            </div>
            

            <Footer copyrightsBackground="orangeBackground" />
        </>
    );
};

export default ListOfProducts;