import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import HeaderNav from "./HeaderNav";
import CustomModal from "./CustomModal";
import "./UserMenu.css";

const UserMenu = ({ firstname, setFirstname, admin, pageName, largeur }) => {

    const [modalMessage, setModalMessage] = useState("");

    const logout = () => {
        localStorage.removeItem('token')
        setModalMessage("¡ Estás desconectado !");
        setFirstname("");
        setTimeout(() => setModalMessage(""), 4000);
    }

    return (
        <div className="userMenu">
            <CustomModal message={modalMessage} show={modalMessage ? true:false} onHide={() => setModalMessage("")} />
            {(largeur <= 700) && <HeaderNav pageName={pageName} userName={firstname} />}
            {!firstname ? (
                <>
                    <h4>¡ Buenos dias ¡</h4>
                    <p>Bienvenidos</p>
                    <ul className="userInfoLink">
                        <li><Link to="/login">Identificarse</Link></li>
                        <li><Link to="/register">Crear cuenta</Link></li>
                    </ul>
                </>
            ) : (
                <>
                    <h4>¡ Hola {firstname} !</h4>
                    <ul className="userInfoLink">
                        {admin && <li><Link to="/admin">Admin</Link></li>}
                        <li><Link to="/my-profile">Mi cuenta</Link></li>
                        <li><Link to="/my-orders">Mi histórico</Link></li>
                        <li><button onClick={logout}>Desconectar</button></li>
                    </ul>
                </>
            )}
            
        </div>
    );
};

export default UserMenu;