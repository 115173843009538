import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import './NewsSection.css';
import dogBubble from "../SVG-icons/dogBubble.svg"
import heartBubble from "../SVG-icons/heartBubble.svg"
import starsBubble from "../SVG-icons/starsBubble.svg"
import news2 from "../SVG-icons/news2.png"
import news3 from "../SVG-icons/news3.png"
import girlWithDog from "../SVG-icons/girlWithDog.png"


const NewsSection = () => {

  return (
    <div className="newsSection">
      <Carousel controls={false}>
        <Carousel.Item>
          <img
            id="newsSectionImage1"
            className="newsSectionImage"
            src={girlWithDog}
            alt="First slide"
          />
          <Carousel.Caption>
            <h4>NUTRIMOS EL CARIÑO Y LA VIDA QUE LE DA A TU MUNDO</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
          id="newsSectionImage2"
            className="newsSectionImage"
            src={news2}
            alt="Second slide"
          />

          <Carousel.Caption className="right green">
              <h4>LA MEJOR FORMULA LLEGÓ A MÉXICO</h4>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
          id="newsSectionImage3"
            className="newsSectionImage"
            src={news3}
            alt="Third slide"
          />

          <Carousel.Caption className="orange">
            <h4>CONSIENTES DE LA NUTRICIÓN Y ESPECIALIZACIÓN QUE NECESITA TU PERRO</h4>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
    
  );
};

  
export default NewsSection;