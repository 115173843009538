import React from 'react';
import Header from "../common/Header";
import Footer from "../common/Footer";
import './payment.css';

const PaymentAccepted = () => {

    return (
        <>
            <Header pageName='NUTRICIÓN PERROS' />
            <div className="emptyDiv">  
            </div>
            
            <div className="paymentSection">
                
            </div>
            <div className="paymentSection">
                <div className="paymentSection2 blueBackground">
                    <h2>Echec du paiement !</h2>
                    <p>Le paiement a recontré une erreur. Veuillez réessayer !</p>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default PaymentAccepted;