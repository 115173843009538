import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from "../common/Header";
import Footer from "../common/Footer";
import CustomModal from "../common/CustomModal";

const { REACT_APP_API_URL } = process.env;

const Login = () => {

    const history = useHistory();

    const [mail, setMail] = useState("");
    const [password, setPassword] = useState("");

    const [modalMessage, setModalMessage] = useState("");

    const connexion = () => {
        axios
        .post(`${REACT_APP_API_URL}/api/user/login`, { email: mail, password })
        .then(res => {
            localStorage.setItem('token', res.data.token);
            setModalMessage("Conexión exitosa !");
            setTimeout(() => history.push('/'), 4000);
        })
        .catch(err => {
            setModalMessage("Usuario no encontrado");
            setTimeout(() => setModalMessage(""), 4000);
        });
    };

    return (
        <>
            <Header pageName='LOGIN' />
            <div className="emptyDiv">  
            </div>
            
            <CustomModal message={modalMessage} show={modalMessage ? true:false} onHide={() => setModalMessage("")} />

            <div className="contactFormSection">
                <div className="registerForm">

                    <h1>Identificarse</h1>

                    <input
                        className="textInput"
                        placeholder="Correo electrónico:"
                        type="text"
                        value={mail}
                        onChange={evt => setMail(evt.currentTarget.value)}
                    />

                    <input
                        className="textInput"
                        placeholder="Contraseña:"
                        type="password"
                        value={password}
                        onChange={evt => setPassword(evt.currentTarget.value)}
                    />

                    <div className="formButtons">
                        <button className="purpleButton" onClick={connexion}>Identificarse</button>
                        <button className="purpleButton" onClick={e => history.push('/register')}>Crear cuenta</button>
                    </div>
                </div>
            </div>

            <Footer />
        </>
    );
};

export default Login;